import React, { useState, useEffect } from "react";

import ReactMarkdown from "react-markdown";

// https://github.com/parcel-bundler/parcel/discussions/9113
import "property-information"; // a parcel bug

export default () => {
  const [text, setText] = useState("");

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      await fetch("/static/carve.md")
        .then((res) => res.text())
        .then((t) => setText(t));
    };
    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, []);

  return (
    <div className="container container-text">
      {text && <ReactMarkdown children={text} />}
    </div>
  );
};
