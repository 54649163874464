/**
 * @kerfed/client
 *
 * Helper functions for calling `engine.kerfed.com/v2` using `fetch`.
 */

import {
  AccessTokenResponse,
  FileBlob,
  GeometryRequest,
  GeometryResponse,
} from "./models";

export class EngineClient {
  // the secret API key
  apiKey: string;

  // the API url for the Kerfed Engine V2 API
  // the V1 API relates to e-commerce specifics
  // where V2 is an ephemeral CAD analysis API
  apiUrl: string = "https://engine.gcp.kerfed.dev/api/v2";

  // The Oauth2 token.
  token: AccessTokenResponse | undefined;

  // Null token to expire immediately.
  token_expires: number = 0;

  /**
   *
   * @param apiKey your API key for `engine.kerfed.com`
   * @param apiUrl if you are using a non-default API endpoint.
   *
   */
  constructor(apiKey: string, apiUrl: string | undefined = undefined) {
    this.apiKey = apiKey;

    if (apiUrl) {
      this.apiUrl = apiUrl;
    }
  }

  /**
   *
   * @param file_name the name of the file you want to temporarily upload
   * @returns
   */
  async upload(filename: string) {
    const [__, body] = await this.apiFetch("upload", { filename }, "POST");
    return body;
  }

  /**
   * Start an analysis using a signed URL and return immediately.
   *
   * @param request
   * @returns
   */
  async start(request: GeometryRequest): Promise<string> {
    /**
  const request: GeometryRequest = {
    source: {
      url: source_url,
      name: 'hi.stl',
    },
    source_units,
    return_previews: true,
    settings: { mill: { return_gcode: true } },
  };  */

    // only load the example file if it isn't already loaded
    const [_, { task_id }] = await this.apiFetch(`geometry`, request, "POST", 30.0);

    return task_id;
  }

  async startFile(
    file: FileWithPath,
    request?: GeometryRequest,
  ): Promise<string> {
    const [body, { upload_url, file_id }] = await Promise.all([
      readFile(file),
      this.upload(file?.path),
    ]);
    // upload the file data
    await fetch(upload_url, { method: "PUT", body });

    // replace the raw file data with the file identifier
    const source: FileBlob = { file_id, name: file.path };

    // If there were no details specified for the request just start
    if (request === undefined) {
      return this.start({ source });
    }
    // overwrite any source data with the passed file
    request.source = source;
    return this.start(request);
  }

  /**
   * Poll a created job until we're done.
   *
   *
   * @param task_id what was the task_id returned for this job
   * @param timeout how long before we declare the job failed
   * @param period how long in seconds to sleep between polling
   * @param setProgress an optional callback to set progress 0.0-1.0
   * @returns
   */
  async poll(
    task_id: string,
    timeout: number = 60,
    period: number = 0,
    setProgress?: Function,
  ): Promise<GeometryResponse> {
    // compute the timeout end stamp converting timeout
    // seconds to milliseconds
    const end = Date.now() + timeout * 1000.0;
    while (true) {
      // get an update from the geometry status route
      const [code, blob] = await this.apiFetch(`geometry/${task_id}`, null);
      if (code == 200) {
        // code 200 means the work has completed sucessfully
        // and the response blob is a GeometryResponse
        return blob;
      } else if (code >= 300) {
        // all success codes are 200-299
        throw new Error(`HTTP Error ${code}`);
      } else if (code == 202) {
        // code 202 includes a progress estimate which if we
        // were passed a callback we should use
        setProgress && setProgress(blob?.progress);
      }
      // check for timeout
      if (Date.now() > end) {
        throw new Error(`Timed out`);
      }
      // sleep for the requested period in milliseconds before polling
      await wait(period * 1000);
    }
  }

  /**
   * Fetch from `this.apiUrl` using `this.apiKey` in the header.
   *
   * @param route the url fragment, i.e. 'geometry'
   * @param body the body to pass to this route
   * @param method 'POST', 'GET', etc
   * @param timeout how long should the server wait to respons
   * @returns
   */
  async apiFetch(
    route: string,
    body: any,
    method: string = "GET",
    timeout: undefined | number = 5,
  ): Promise<[number, any]> {
    // check for no token or expired token
    if (!this?.token?.access_token || Date.now() > this.token_expires) {
      const resp = await fetch(`${this.apiUrl}/auth/token`, {
        method: "POST",
        body: apiKeytoCredentials(this.apiKey),
      });

      const auth = await resp.json();

      // expire the token at 90% of its claimed lifetime
      this.token_expires = Date.now() + auth.expires_in * 900;

      // save the retrieved token
      this.token = auth;
    }

    const res = await fetch(`${this.apiUrl}/${route}`, {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token?.access_token}`,
        ...(timeout && { "Request-Timeout": Number(timeout).toFixed(0) }),
      },

      ...(body && { body: JSON.stringify(body) }),
    });
    return [res.status, await res.json()];
  }
}

/**
 * Wait for a period of time.
 *
 * @param ms how long to sleep for
 * @returns
 */
function wait(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

interface FileWithPath extends File {
  path: string;
}

/**
 * A helper function to read a file and return raw data.
 *
 * @param file
 * @returns
 */
function readFile(file: File): Promise<ArrayBuffer | string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onabort = () => reject("file reading was aborted");
    reader.onerror = () => reject("file reading has failed");
    reader.onload = () => {
      // run our callback on the file data
      const result = reader.result;
      if (result === null) {
        reject("Null read?");
        return;
      }
      resolve(result);
    };
    reader.readAsArrayBuffer(file);
  });
}

/**
 *
 * @param apiKey API key for the kerfed engine.
 * @returns body for a fetch: `fetch(... body: apiKeyToCredentials(apiKey))`
 */
function apiKeytoCredentials(apiKey: string): URLSearchParams {
  // apiKey format is is `header_clientId_clientSecret`
  const check = apiKey.split("_");
  if (check.length !== 3) {
    throw new Error("invalid API key format!");
  }

  const form = new URLSearchParams();
  form.set("grant_type", "client_credentials");
  form.set("client_id", check[1]);
  form.set("client_secret", check[2]);

  return form;
}
