import React from "react";
import { Link } from "react-router-dom";

export default () => {
  return (
    <nav className="navbar navbar-expand-lg fixed-top">
      <div className="container">
        <Link className="navbar-brand" aria-current="page" to="/">
          CarveWizard
        </Link>
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <Link className="nav-link active" aria-current="page" to="/upload">
              UPLOAD
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link active" aria-current="page" to="/tech">
              TECH
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};
