import React, { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useDropzone } from "react-dropzone";
import { FileWithPath } from "react-dropzone";

import "../scss/dropzone.scss";
import { EngineClient, GeometryRequest, MillSettings } from "@kerfed/client";

/**
 *
 */
const Drop = ({ onDrop }: any) => {
  const extensions = [
    "step",
    "stp",
    "3dxml",
    "3mf",
    "dae",
    "glb",
    "gltf",
    "obj",
    "off",
    "ply",
    "stl",
  ];

  function validator(file: FileWithPath) {
    const name = file.name.toLowerCase();
    setError("");
    if (!extensions.find((e) => name.endsWith(e))) {
      setError(`${file.name} is of unsupported format!`);
      return {
        code: "unsupported-format",
        message: `File is not of supported type!`,
      };
    }

    return null;
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop, validator });

  const [error, setError] = useState("");
  return (
    <div>
      <div className="dropzone" {...getRootProps()}>
        <input {...getInputProps()} />
        <p>
          Drag and drop a CAD file here to run G-code generation and other
          analysis.
        </p>
        <p>
          <em>{extensions.map((e) => e.toUpperCase()).join(", ")}</em>
        </p>
        {error && <p>{error}</p>}
      </div>
    </div>
  );
};

export default () => {
  // after we've uploaded users navigae
  const navigage = useNavigate();

  // Save the form values edited by the user
  const [uploading, setUploading] = useState(false);
  const [radial, setRadial] = useState(() => 0.12);
  const [spindle, setSpindle] = useState("");
  const [feed, setFeed] = useState("");
  const [units, setUnits] = useState(() => "");
  const [material, setMaterial] = useState(() => "");

  const apiCall = (): GeometryRequest => {
    // Get the machine settings for valid values
    const machine = {
      ...(spindle && !isNaN(spindle) && { rpm_max: Number(spindle) }),
      ...(feed && !isNaN(feed) && { feed_max: Number(feed) }),
    };

    // Construct the API call from the current page state.
    const settings: MillSettings = {
      return_gcode: true,
      ...(radial !== 0.12 && { default_radial_depth_of_cut: radial }),
      ...(material &&
        material !== "Automatic" && {
          material_id: `${material.toLowerCase()}*`,
        }),
      ...(Object.keys(machine).length && { machine }),
    };

    const result: GeometryRequest = {
      brand: { name: "CarveWizard" },
      source: {
        name: "featuretype.glb",
        url: `https://carvewizard.com/static/featuretype.glb`,
        sha256:
          "9dc3e7e5008c105a7ae931539c296281b4a34655a7c3d584f30983dd7a45b8d7",
      },
      ...(units && units !== "Automatic" && { source_units: units }),
      settings: { mill: settings },
    };

    return result;
  };

  const apiKey =
    "kerfed_9LWfm6nWMnMr7rtjfNRTW_WtBndBhr6HTD6bRrHMpKmzfJWNjwNwcPfdKttdrqKjCKQNnG";
  const engine = new EngineClient(apiKey);

  const onUpload = (task_id: string) => navigage(`/upload/${task_id}`);
  const onSampleFile = useCallback(async (request: GeometryRequest) => {
    setUploading(true);
    const task_id = await engine.start(request);
    return onUpload(task_id);
  }, []);
  const onDrop = async (acceptedFiles: FileWithPath[]) => {
    if (acceptedFiles.length !== 1) {
      return;
    }
    setUploading(true);
    const task_id = await engine.startFile(acceptedFiles[0], apiCall());
    return onUpload(task_id);
  };

  return (
    <div className="container my-5">
      <h1>CarveWizard CAM</h1>
      <p>Upload a 3D model and have our pipeline generate CAM information.</p>

      {uploading ? (
        <div className="text-center align-middle my-5">
          <div
            className="spinner-border text-primary"
            style={{ width: "10rem", height: "10rem" }}
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <Drop onDrop={onDrop} />
          <button
            type="button"
            className="btn btn-outline-primary my-2"
            disabled={uploading}
            onClick={() => onSampleFile(apiCall())}
          >
            Press to use sample file &#x2192;
          </button>{" "}
        </>
      )}
      <div className="my-5">
        <h2>API Overrides</h2>
        <p>
          The API is designed to take minimal configuration. Philosophically it
          tries to take no "per-part" information (i.e. "click on this face"),
          and instead take per-facility information (i.e. "our spindle is capped
          at 4000 RPM")
        </p>
        <form>
          <div className="form-row">
            <div className="form-group mb-3 col-md-6">
              <h4>Source File Units</h4>
              <p>
                If the model file doesn't contain unit information, i.e. a DXF,
                STL, OBJ file, specify the units of the source file being
                uploaded above.
              </p>
              <select
                defaultValue="Automatic"
                id="inputUnits"
                className="form-control"
                onChange={(e) => setUnits(e.target.value.trim())}
              >
                <option>Automatic</option>
                <option>Inches</option>
                <option>Meters</option>
                <option>Millimeters</option>
              </select>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group mb-3 col-md-6">
              <h4 htmlFor="inputRadial">
                Radial Depth Of Cut ({(radial * 100).toFixed(1)}
                %)
              </h4>
              <p>
                The radial depth of cut is the percentage of a tool radius each
                "pass" should be. It can be set per-tool, or this default value
                will apply otherwise
              </p>
              <input
                type="range"
                min="0.02"
                max="2"
                step="0.02"
                value={radial.toFixed(3)}
                className="form-range"
                id="inputRadial"
                onChange={(e) => setRadial(Number(e.target.value))}
                placeholder="Automatic"
              ></input>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group mb-3 col-md-6">
              <h4>Spindle Speed Maximum</h4>
              <p>
                The default spindle speed is 8000rpm for an industrial VMC which
                may be higher than desired. Capping the spindle speed will scale
                feed rate down to match the material-specific "surface feet per
                minute calculation." The spindle cap is in rotations per minute.
              </p>
              <input
                id="inputUnits"
                className={`form-control ${!spindle || (isNaN(spindle) && "is-invalid")}`}
                value={spindle}
                onChange={(e) => setSpindle(e.target.value.trim())}
                placeholder="Default Spindle Speed"
              />
            </div>

            <div className="form-group mb-3 col-md-6">
              <h4>Linear Speed Maximum</h4>
              <p>
                Set the maximum linear feed rate the machine is capable of to
                scale down spindle speeds to match feed rate tables. Units are
                meters per minute.
              </p>
              <input
                id="inputUnits"
                className={`form-control ${!feed || (isNaN(feed) && "is-invalid")}`}
                value={feed}
                onChange={(e) => setFeed(e.target.value.trim())}
                placeholder="Default Linear Speed"
              />
            </div>

            <div className="form-group mb-3 col-md-6">
              <h4>Material Family</h4>
              <p>
                Select the material family that speeds and feeds should be
                generated for.
              </p>
              <select
                defaultValue="Automatic"
                id="inputMaterial"
                className="form-control"
                onChange={(e) => setMaterial(e.target.value)}
              >
                <option>Automatic</option>
                <option>Aluminum</option>
                <option>Steel</option>
              </select>
            </div>
            <div className="col"></div>
          </div>
        </form>
        <div className="my-5">
          <h2>API Request</h2>
          <p>
            The settings on the form above will result in the following API
            call. This is an API demo for{" "}
            <a target="_blank" href="https://engine.gcp.kerfed.dev">
              https://engine.gcp.kerfed.dev
            </a>{" "}
            generating a{" "}
            <a
              target="_blank"
              href="https://buf.build/kerfed/protos/docs/main:kerfed.protos.api.v1#kerfed.protos.api.v1.GeometryRequest"
            >
              GeometryRequest
            </a>{" "}
            and parsing the{" "}
            <a
              target="_blank"
              href="https://buf.build/kerfed/protos/docs/main:kerfed.protos.api.v1#kerfed.protos.api.v1.GeometryResponse"
            >
              GeometryResponse
            </a>{" "}
            into the displayed G-code. If you upload a file using the widget
            above, it will replace the example file in <code>source</code> with
            the file you have uploaded.
          </p>
          <div className="card card-body mx-5">
            <pre>
              <code>
                const request: GeometryRequest ={" "}
                {JSON.stringify(apiCall(), null, 2)}
              </code>
            </pre>
          </div>
          <hr />
        </div>
      </div>
    </div>
  );
};
