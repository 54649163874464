import React from "react";
import { Link } from "react-router-dom";

export default () => (
  <header className="masthead">
    <div className="container">
      <div className="grid">
        <div className="row">
          <div className="col-lg-7 header-column my-5">
            <div className="header-content my-5 p-3">
              <h2 className="mb-5">
                CarveWizard is an experimental GPU G-code planner that takes 3D
                geometry and outputs milling tool paths.
              </h2>
              <p>
                GPUs used for AI come with substantial amounts of video RAM,
                which enables us to operate on the part <i> volume </i>
                rather than the
                <i> surface </i> as a B-REP kernel would.
              </p>
              <p>
                This enables large parallelization speedups and also produces
                paths which have been 100% collision checked and shortcut in a
                volume-aware way so that every segment is cutting. Check out a{" "}
                <a
                  href="https://www.youtube.com/watch?v=v_Pb2hIG47Y"
                  target="_blank"
                >
                  video demo
                </a>
                .
              </p>
              <Link className="btn btn-outline btn-xl" to="/upload">
                Upload CAD
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
);
