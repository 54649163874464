import React, { useMemo, useState } from "react";
import { useParams } from "react-router";
import {
  EngineClient,
  PartFabrication,
  GeometryResponse,
} from "@kerfed/client";
import "../scss/dropzone.scss";

/**
 * Return the raw HTML of the first successful G-code milling result.
 *
 * @param analysis
 * @returns
 */
export async function parseFabrication(
  analysis: Partial<PartFabrication>[],
): Promise<string> {
  for (let a of analysis) {
    if (a.mill?.outcome?.is_success) {
      const mill = a.mill;

      const summary = mill.gcode.find(
        (gcode) => gcode.name === "summary.html",
      )?.url;
      if (!!summary) {
        let text = await (await fetch(summary)).text();
        console.log({ text });

        for (const fn of mill.gcode) {
          if (fn?.name && fn?.url) {
            text = text.replace(`"${fn.name}"`, fn.url);
          }
        }
        return text;
      }
    }
  }
  return "";
}

export default () => {
  // the ArrayBuffer for our KDC

  const apiKey =
    "kerfed_9LWfm6nWMnMr7rtjfNRTW_WtBndBhr6HTD6bRrHMpKmzfJWNjwNwcPfdKttdrqKjCKQNnG";
  const engine = new EngineClient(apiKey);

  const [analysis, setAnalysis] = useState({});
  const [summary, setSummary] = useState<string>("");
  const [progress, setProgress] = useState<number>(-1.0);
  const { task_id } = useParams();

  // only call poll once using `useMemo` depending on the task_id
  if (task_id) {
    useMemo(
      () =>
        engine
          .poll(task_id, 60, 0, setProgress)
          .then(async (res: GeometryResponse) => {
            if (res?.analysis) {
              setProgress(1.0);
              setAnalysis(res.analysis);
              setSummary(await parseFabrication(res.analysis));
            }
          })
          .catch((err) => {
            console.error(err);
            setSummary(`<div class="alert alert-danger text-center my-5" role="alert">
        ${err.message}
      </div>`);
          }),
      [task_id],
    );
  }

  return (
    <div className="container my-5">
      <div className="my-2">
        {!summary && (
          <div className="text-center align-middle my-5">
            <div
              className={`spinner-border ${progress >= 0.0 ? "text-success" : "text-primary"}`}
              style={{ width: "10rem", height: "10rem" }}
              role="status"
            ></div>
            {progress < 0.0 && <h4>Queued</h4>}
            {progress === 0.0 && <h4>Running</h4>}
            {progress > 0.0 && <h4>{(progress * 100).toFixed(0)}%</h4>}
          </div>
        )}

        {summary && <div dangerouslySetInnerHTML={{ __html: summary }} />}

        {Object.keys(analysis).length ? (
          <div className="card card-body mx-5">
            <p>The full JSON result from the analysis.</p>
            <pre>
              <code>
                const request: GeometryResponse ={" "}
                {JSON.stringify(analysis, null, 2)}
              </code>
            </pre>
          </div>
        ) : null}
      </div>
    </div>
  );
};
